/* === SCSS COLOUR VARIABLES === */

$backgroundteal: #266c8e;
$textteal: #0a5656;
$borderpink: #f43b5d;
$textpink: #f50cf7;
$darkslate: #344f63;
$doorback: #aebeca6b;
$titlebackground: #ffffff;
$numbers: #ffffff;
$linkbuttonhover: #2883af;
$col-sky-top: #bbcfe1;
$col-sky-bottom: #e8f2f6;
$col-fg: #5d7399;
$col-blood: #dd4040;
$col-ground: #f6f9fa;

/* SCSS RESPONSIVE TITLE TYPE @MIXIN */

/// Viewport sized typography with minimum and maximum values
/// @author Eduardo Boucas (@eduardoboucas)
///
/// @param {Number}   $responsive  - Viewport-based size
/// @param {Number}   $min         - Minimum font size (px)
/// @param {Number}   $max         - Maximum font size (px)
///                                  (optional)
/// @param {Number}   $fallback    - Fallback for viewport-
///                                  based units (optional)
///
/// @example scss - 5vw font size (with 50px fallback),
///                 minumum of 35px and maximum of 150px
/// @include responsive-font(5vw, 35px, 150px, 50px);

@mixin responsive-font($responsive, $min, $max: false, $fallback: false) {
  $responsive-unitless: $responsive / ($responsive - $responsive + 1);
  $dimension: if(unit($responsive)=='vh', 'height', 'width');
  $min-breakpoint: $min / $responsive-unitless * 100;

  @media (max-#{$dimension}: #{$min-breakpoint}) {
    font-size: $min;
  }

  @if $max {
    $max-breakpoint: $max / $responsive-unitless * 100;

    @media (min-#{$dimension}: #{$max-breakpoint}) {
      font-size: $max;
    }
  }

  @if $fallback {
    font-size: $fallback;
  }

  font-size: $responsive;
}

/* =============================== */

body {
  font-family: 'Montserrat', Verdana;
}

.background {
  height: 98vh;
  min-height: 450px;
  position: relative;

  z-index: 1;
  background-color: mix($col-sky-top, $col-sky-bottom);
  background-image: url("https://raw.githubusercontent.com/AnwenW/advent-calendar/master/images/brighton-images/snow.png"), linear-gradient(to bottom, $col-sky-top 0%, $col-sky-bottom 80%);
}

/* === MOBILE-FIRST GRID LAYOUT === */

.calendar-grid {
  display: grid;
  width: 96%;
  height: 94vh;
  max-width: 900px;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: auto;
  grid-gap: 15px;

  grid-template-areas: "day11    day0      day18"
    "day9     day1      day10"
    "day23    day20     day12"
    "day2     day14     day4"
    "day24    day24     day24"
    "day24    day24     day24"
    "day5     day22     day16"
    "day13    day3      day15"
    "day6     day17     day8"
    "day19    day7      day21";
  margin-left: auto;
  margin-right: auto;
  padding-top: 10px;
  padding-bottom: 10px;
}


/* MEDIA QUERY -- DESKTOP LAYOUT */

@media only screen and (min-width: 520px) {

  body {
    font-family: 'Montserrat', Verdana;
  }

  .background {
    background-image: url(./assets/img/background.png);
    background-repeat: no-repeat;
    background-size: 100%;
  }

  .calendar-grid {
    grid-template-columns: repeat(5, 1fr);
    grid-template-areas: "day8      day12     day13     day2      day7      "
      "day20     day22     day15     day4      day11     "
      "day1      day24     day24     day24     day9      "
      "day6      day24     day24     day24     day21     "
      "day17     day18     day0      day19     day5      "
      "day3      day23     day16     day14     day10     ";

  }
}

/* === INDIVIDUAL GRID ITEMS ===  */

.title {
  grid-area: title;
  text-align: center;
  color: $textteal;
}

.title-lines-container {
  background: $titlebackground;
  border-radius: 30px 0;
  border: 5px solid $borderpink;
  transform: rotate(2deg);
  margin: 1.5% 0 0 0;
}

.title-line1 {
  font-family: 'Oleo Script Swash Caps', Courier;
  @include responsive-font(4vw, 30px, 60px, 45px);
  margin: 0;
  padding: 10px 15px 0;
}

.title-line2 {
  font-family: 'BioRhyme', Courier;
  @include responsive-font(3vw, 21px, 44px, 35px);
  line-height: 1.1em;
  margin: 0;
  padding: 0 15px 15px;
}

.bigger-text {
  @include responsive-font(3.5vw, 26px, 50px, 40px);
}

.title-intro {
  background: $titlebackground;
  padding: 20px 25px;
  border: 1px solid #0a5656;
  border-radius: 0 30px;
  line-height: 1.5em;
  transform: rotate(-1.5deg);
  margin: 5% 2% 2%;
}

.vip {
  background-image: url('./assets/img/vip.png');
}

.rien {
  background-image: url('./assets/img/rien.png');
}

.holy {
  background-image: url('./assets/img/holy.png');
}

.roulette {
  background-image: url('./assets/img/roulette.png');
}

.sub {
  background-image: url('./assets/img/sub.png');
}

.holystarter {
  background-image: url('./assets/img/holystarter.png');
}

.grade {
  background-image: url('./assets/img/grade.png');
}

.pack {
  background-image: url('./assets/img/pack.png');
}

.sub5 {
  background-image: url('./assets/img/sub5.png');
}

.jeu30 {
  background-image: url('./assets/img/jeu30.png');
}

.day1 {
  grid-area: day1;
}

.day2 {
  grid-area: day2;
}

.day3 {
  grid-area: day3;
}

.day4 {
  grid-area: day4;
}

.day5 {
  grid-area: day5;
}

.day6 {
  grid-area: day6;
}

.day7 {
  grid-area: day7;
}

.day8 {
  grid-area: day8;
}

.day9 {
  grid-area: day9;
}

.day10 {
  grid-area: day10;
}

.day11 {
  grid-area: day11;
}

.day12 {
  grid-area: day12;
}

.day13 {
  grid-area: day13;
}

.day14 {
  grid-area: day14;
}

.day15 {
  grid-area: day15;
}

.day16 {
  grid-area: day16;
}

.day17 {
  grid-area: day17;
}

.day18 {
  grid-area: day18;
}

.day19 {
  grid-area: day19;
}

.day20 {
  grid-area: day20;
}

.day21 {
  grid-area: day21;
}

.day22 {
  grid-area: day22;
}

.day23 {
  grid-area: day23;
}

.day24 {
  grid-area: day24;
}

.texte-gain {
  font-size: 15px;
}

/* ===  DOOR STYLES ===  */

.calendar-grid input {
  display: none;
}

.door {
  width: 100%;
  height: 100%;
  perspective: 1000px;
  transform-style: preserve-3d;
  transition: all 300ms;
  border: 1.5px dashed $darkslate;
  border-radius: 10px;
  cursor: pointer;
  font-family: 'BioRhyme', serif;
  font-size: 58px;
  color: $numbers;

  &.no-hover {
    border-color: $darkslate;
  }

  &:not(.no-hover):hover {
    border: 1.5px dashed $numbers;
  }
}

.door div {
  position: absolute;
  height: 100%;
  width: 100%;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.door .front {
  background: rgba(172, 171, 171, 0.24);
  text-shadow: 2px 2px 0 $darkslate,
    2px -2px 0 $darkslate,
    -2px 2px 0 $darkslate,
    -2px -2px 0 $darkslate,
    2px 0px 0 $darkslate,
    0px 2px 0 $darkslate,
    -2px 0px 0 $darkslate,
    0px -2px 0 $darkslate;
}

.door .back {
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
  background-color: $doorback;
  transform: rotateY(180deg);
}

.open {
  transform: rotateY(180deg);
}

footer {
  height: 50px;
  padding: 0px 15px 10px;
  text-align: right;

  p {
    margin-top: 25px;
    color: #222222;
  }
}

/* ===  MODAL (BACKGROUND) ===  */

.modal {
  display: none;
  /* Hidden by default */
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  /* Enable scroll if needed */
  background-color: rgb(0, 0, 0);
  /* Fallback color */
  background-color: rgba(0, 0, 0, 0.4);
  /* Black w/ opacity */
}

/* === MODAL CONTENT + BOX ===  */

.modalContent {
  background-color: $titlebackground;
  margin: 15% auto;
  padding: 20px 20px 30px;
  border: 4px solid $borderpink;
  border-radius: 6px;
  width: 76%;
  max-width: 600px;
  min-height: 200px;
  letter-spacing: 0.5px;
  line-height: 1.4em;

  p {
    font-size: 0.9em;
  }
}

.dailyTitle {
  font-family: 'BioRhyme', Courier;
  color: $doorback;
}

.dailyDate {
  color: $textpink;
}

.highlight {
  font-weight: bold;
  color: $textpink;
}

.dailyLink {
  background-color: $doorback;
  padding: 5px 8px;
  border-radius: 6px;
  text-decoration: none;
  color: $numbers;
  font-weight: bold;
  line-height: 2.3em;

  &:hover {
    background-color: $linkbuttonhover;
  }
}

/* ===  MODAL CLOSE BUTTON (X) ===  */

.close {
  color: #aaa;
  float: right;
  font-size: 48px;
  line-height: 26px;
  font-weight: bold;

  &:hover,
  &:focus {
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
}

@media only screen and (max-width: 519px) {
  .title-line1 {
    @include responsive-font(10vw, 34px, 52px, 38px);
  }

  .title-line2 {
    @include responsive-font(8vw, 27px, 48px, 35px);
  }

  .bigger-text {
    @include responsive-font(10vw, 32px, 52px, 40px);
  }

  .title-intro {
    padding: 18px 18px;
  }
}

@media only screen and (max-width: 690px) {
  .door {
    font-size: 46px;
  }
}

ul.socialIcons {
  padding: 0;
  text-align: center;
}

.socialIcons li {
  background: yellow;
  list-style: none;
  display: inline-block;
  border-radius: 2em;
  overflow: hidden;
  margin-left: 10px;
}

.socialIcons li a {
  display: block;
  padding: 0.5em;
  max-width: 1.5em;
  min-width: 1.5em;
  height: 1.5em;
  white-space: nowrap;
  line-height: 1.5em;
  transition: .5s;
  text-decoration: none;
  font-family: arial;
  color: #fff;
}

.socialIcons li i {
  margin-right: .5em;
}

.socialIcons li:hover a {
  max-width: 200px;
  padding-right: 1em;
}

.socialIcons .facebook {
  background: #3b5998;
}

.socialIcons .twitter {
  background: #00aced;
}

.socialIcons .instagram {
  background: #cd486b;
}

.socialIcons .youtube {
  background: #c92228;
}

.socialIcons .steam {
  background: #666666;
}

.socialIcons .twitch {
  background: #4b367c;
}

.socialIcons .discord {
  background: #7289DA;
}

.socialIcons .extension {
  background: rgb(224, 143, 13);
}

.fa-fx {
  width: 1.58571429em;
  text-align: center;
}

.compteur {
  text-align: center;
  font-weight: bold;
}
